import axios from "axios";
import { useEffect, useState } from "react";

export default function Stats() {
  const [stats, setStats] = useState([]);

  const getStats = () => {
    axios.get("api/stats").then((response) => {
      setStats(response.data);
    });
  };

  useEffect(() => {
    getStats();
  }, []);

  return (
    <div className="statsContainer">
      <h3>Stats</h3>
      <ul>
        {stats.map((e, key) => (
          <a href={`/${e.key}`} key={key}>
            <li key={key}>
              Team <strong>{e.clientName}</strong> : {e.count} images uploaded{" "}
              {e.count ? " 🎉" : "😞"}
            </li>
          </a>
        ))}
      </ul>
    </div>
  );
}
