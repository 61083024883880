import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TeamComponent from "./client/components/teamComponent";
import Stats from "./client/components/stats";

function MainComponent() {
  const rawEnvVar = process.env.REACT_APP_CLIENT_KEYS;
  const clientsKeyValues = rawEnvVar.split(",");

  return (
    <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />}>
          {
            clientsKeyValues.map((e, key) => {
              const oneClientValues = e.split(":");
              const path = oneClientValues[0].trim();
              const clientName = oneClientValues[1].trim();

              return <Route
                key={key}
                path={path}
                element={<TeamComponent teamName={clientName} path={path} />}
              />
            })
          }
          <Route path="stats" element={ Stats } />
        </Route>
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
  );
}

ReactDOM.render(MainComponent(), document.getElementById("root"));
