import "../scss/history.scss";

export default function History() {
    return (

        <div className="timeline">
            <div className="container container-left">
                <div className="image" style={{backgroundImage: "url('https://upload.wikimedia.org/wikipedia/commons/thumb/3/32/Flag_of_Pakistan.svg/1280px-Flag_of_Pakistan.svg.png')"}}></div>
                <div className="content">
                    <span>January 2007</span>
                    <h2>Setup in Pakistan</h2>
                    <p>
		    	After volunteering for the UN at the relief efforts after the 2005 Muzzafrabad earthquake, Bart and Jelle figured out the opportunities of Pakistan as a source of talented IT profiles. Zeropoint was born in Islamabad.
                    </p>
                </div>
            </div>
            <div className="container container-right">
                <div className="image" style={{backgroundImage: "url('https://upload.wikimedia.org/wikipedia/commons/thumb/1/11/Flag_of_Sri_Lanka.svg/1280px-Flag_of_Sri_Lanka.svg.png')"}}></div>
                <div className="content">
                    <span>January 2012</span>
                    <h2>Start in Sri Lanka</h2>
                    <p>
			A great opportunity presented itself when Zeropoint was able to acquire Euroceylon Software, an IT service company established in Colombo. An intense and successful integration period followed and soon Zeropoint was operating from two South Asian bases with its signature business model.
                    </p>
                </div>
            </div>
            <div className="container container-left">
                <div className="image" style={{backgroundImage: "url('https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/Flag_of_Belgium.svg/1024px-Flag_of_Belgium.svg.png')"}}></div>
                <div className="content">
                    <span>January 2022</span>
                    <h2>You are with us</h2>
                    <p>
			We are extremely grateful to be celebrating our 15th birthday with you as our customer. Ultimatly, it is you who allows us to fulfil our mission of creating work environments wherein people can exceed their own expectations of themselves. Thank you for being here and celebrating with us!
                    </p>
                </div>
            </div>
    </div>

    );
}
