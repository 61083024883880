import { useState, useEffect } from "react";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import Gallery from "./gallery";

export default function Upload(props) {
  const [ image, selectPhoto ] = useState();
  const [ imageName, setImageName ] = useState("");
  const [ showUploadBtn, setUploadBtnState ] = useState(false);
  const [ images, setImages ] = useState([]);
  const [ showHomeLink, setHomeLinkVisibility ] = useState(false);
  const teamPath = props.path;
  const maxSize = 5 * 1024 * 1024

  const handleChange = (event) => {
    if(event.target.files.length > 0) {
      const size = event.target.files[0].size;

      if(size > maxSize) {
        toast.error("File too big. Please upload photos that are less than 5mb")
        return;
      }

      setImageName(event.target.files[0].name);
      selectPhoto(URL.createObjectURL(event.target.files[0]));
      setUploadBtnState(true);
    }
  };

  const loadImages = async () => {
    await axios.get(`api/photos/${props.path}`).then((response) => {
      setImages(response.data);
      setHomeLinkVisibility(response.data && response.data.length >= 3)
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      await loadImages();
    }

    fetchData();
  }, []);

  const uploadImage = async () => {
    let blob = await fetch(image).then((r) => r.blob());

    const formData = new FormData();
    formData.append("teamPath", teamPath);
    formData.append("image", blob, imageName);

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };

    await toast.promise(axios.post("api/upload", formData, config), {
      loading: "Uploading...",
      success: () => {
        clearSelection();
        loadImages();
        return <b>Uploaded successfully!</b>;
      },
      error: (err) => <b>{err.response.data}</b>,
    });
  };

  const clearSelection = () => {
    selectPhoto(null);
    setUploadBtnState(false);
  };

  const onDeleted = async () => {
    await loadImages();
  }

  return (
    <>
      <label
        className="inputImageControl"
        htmlFor="inputImage"
        style={{ display: showUploadBtn ? "none" : "inline-block" }}
      >
        <div className="selectBtn">
          Capture or select a photo
          <div className="plusBtn">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><circle cx="256" cy="275" r="57.5"/><path d="M417.5 160H363c-4.6 0-8.9-2-12-5.4-28.4-31.8-39.1-42.6-50.7-42.6h-85.5c-11.7 0-23.2 10.8-51.7 42.7-3 3.4-7.4 5.3-11.9 5.3h-4.1v-8c0-4.4-3.6-8-8-8h-26c-4.4 0-8 3.6-8 8v8h-7.5C79.9 160 64 173.2 64 190.7v176c0 17.5 15.9 33.3 33.5 33.3h320c17.6 0 30.5-15.8 30.5-33.3v-176c0-17.5-12.9-30.7-30.5-30.7zM260 360.4c-50.3 2.3-91.7-39.1-89.4-89.4 2-43.9 37.5-79.4 81.4-81.4 50.3-2.3 91.7 39.1 89.4 89.4-2 43.9-37.5 79.4-81.4 81.4zM352 218c-7.2 0-13-5.8-13-13s5.8-13 13-13 13 5.8 13 13-5.8 13-13 13z"/></svg>
          </div>
        </div>
        <span className="lightSmallText">
          A preview will be shown <strong>before</strong> you upload
        </span>
      </label>
      <input
        id="inputImage"
        name="inputImage"
        hidden
        type="file"
        onChange={handleChange}
        capture="environment"
        accept="image/*"
      />

      <img src={image} className="selectedImage" alt="" />
      <div
        className="selectedSection"
        style={{ display: showUploadBtn ? "inline-block" : "none" }}
      >
        <button className="uploadBtn" onClick={uploadImage}>
          Upload this photo
        </button>
        <button className="clearSelectionBtn" onClick={clearSelection}>
          Clear selection
        </button>
      </div>

      <a className="home-link" href="/" hidden={!showHomeLink}>Click here to read <br /> the Zeropoint story!</a>
      <span className="upload-nag" hidden={showHomeLink}>Please upload at least 3 photos</span>

      <Gallery photos={images} onDeleted={onDeleted} teamPath={props.path} />

      <Toaster />
    </>
  );
}
