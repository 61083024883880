import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import toast from "react-hot-toast";
import axios from "axios";
import 'react-image-lightbox/style.css';

export default function Gallery(props) {
  const [ photoIndex, setPhotoIndex ] = useState(0);
  const [ isOpen, setIsOpen ] = useState(false);
  const placeholders = [1, 2, 3];

  props.photos.forEach(e => {
    if(placeholders.length > 0){
      placeholders.splice(0, 1);
    }
  });

  const confirmDelete = async (filePath) => {
    const filename = filePath.split('/').pop();

    const data = {
      key: props.teamPath,
      filename
    }

    const config = {
      headers: { "content-type": "application/json" },
    };

    const result = window.confirm("Are you sure you want to delete this photo?")
    if(result) {
      await toast.promise(
        axios.post("api/delete", data, config), {
        loading: "Deleting...",
        success: () => {
          props.onDeleted()
          return <b>Deleted successfully!</b>;
        },
        error: (err) => {
          props.onDeleted();
          return <b>{err.response.data}</b>;
        },
      });
    }
  }


return (
  <>
    <div className="gallery">
      <div className='onePhotoContainer sample-photo'>
        <img src="/sample.jpg" className='onePhoto' alt='Sample'></img>
      </div>

      {props.photos.map((e, key) => (
        <div className='onePhotoContainer' key={key}>
          <img key={key} src={e.src} className="onePhoto" onClick={() => { setPhotoIndex(key); setIsOpen(true) } } alt='' />
          <button onClick={() => { confirmDelete(e.src) }}>Delete</button>
        </div>
      ))}

      {placeholders.map((e, key) => (
        <div className='onePhotoContainer' key={key}>
          <div className='onePhoto placeholder'>
            {e}
          </div>
        </div>
      ))}
    </div>

    {isOpen && <Lightbox
      mainSrc={ props.photos.length > 0 ? props.photos[photoIndex].src : '' }
      nextSrc={ props.photos[(photoIndex + 1) % props.photos.length].src }
      prevSrc={ props.photos[(photoIndex + props.photos.length - 1) % props.photos.length].src }
      imagePadding = { 50 }
      onCloseRequest = { () => setIsOpen(false) }
      onMovePrevRequest={() =>
        setPhotoIndex((photoIndex + props.photos.length - 1) % props.photos.length)
      }
      onMoveNextRequest = {() => 
        setPhotoIndex((photoIndex + 1) % props.photos.length)
      }
      enableZoom = {false}
    />}
    </>
  );
}
