import Stats from "./stats";
import { useLocation } from 'react-router-dom'
import History from "./history";

export default function Dashboard() {
    const search = useLocation().search;
    const stats = new URLSearchParams(search).get('stats');
    let statsComponent;

    if(stats) {
        statsComponent = <Stats />
    }

    return (
        <>
        <div className="history-container">
            <History />
        </div>

        { statsComponent }
        </>
    );
}