import headerImage from "./client/images/header-responsive.jpg";
import "./client/scss/App.scss";
import { Outlet } from "react-router-dom";
import { useEffect } from "react";
import Dashboard from "./client/components/dashboard";

function App() {
  useEffect(() => {
    document.title = "15 Years of Zeropoint";
  }, []);

  /**
   * Renders the history + dashbaord or the team page conditionally
   */
  const renderDashboardOrTeamPage = () => {
    if(window.location.toString().includes("-")){
      return <Outlet />;
    } else {
      return <Dashboard />;
    }
  };

  return (
    <div className="wrapper">
      <div className="header">
        <img src={headerImage} alt="Banner"></img>
      </div>
      { renderDashboardOrTeamPage() }
    </div>
  );
}

export default App;
