import { useRef, useCallback } from "react";
import ReactCanvasConfetti from "react-canvas-confetti";

export default function Confetti() {
  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const makeShot = useCallback((particleRatio, opts) => {
    refAnimationInstance.current &&
      refAnimationInstance.current({
        ...opts,
        origin: { y: 0.7 },
        particleCount: Math.floor(200 * particleRatio),
      });
  }, []);

  const fire = useCallback(() => {
    makeShot(0.25, {
      spread: 26,
      startVelocity: 55,
      useWorker: true,
    });

    makeShot(0.2, {
      spread: 60,
      useWorker: true,
    });

    makeShot(0.35, {
      spread: 100,
      decay: 0.91,
      scalar: 0.5,
      useWorker: true,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 25,
      decay: 0.92,
      scalar: 0.8,
      useWorker: true,
    });

    makeShot(0.1, {
      spread: 120,
      startVelocity: 45,
      useWorker: true,
    });
  }, [makeShot]);

  return (
    <>
      <button className="celebrateButtonLeft" onClick={fire}>
        🎉
      </button>

      <ReactCanvasConfetti
        refConfetti={getInstance}
        className="confetti-canvas"
        style={{ width: "100%", height: "100%" }}
        width={"100%"}
        height={"100%"}
      />
    </>
  );
}
