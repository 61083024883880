import Upload from "./upload";
import Confetti from "./confetti";

export default function TeamComponent(props) {
  return (
    <div className="teamPageSection">
      <h1>
        Team <span>{props.teamName}</span> <Confetti />
      </h1>
      <span>
        Let's create some memories with team {props.teamName}!
        <br />
        Share your photos with your team by clicking the button below.
      </span>
      <Upload path={props.path} />
    </div>
  );
}
